import React from 'react'
import { Link } from 'gatsby'
import { Button, Box, Heading, Text } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  author: {
    display: `inline-block`,
    color: `alpha`
  },
  occupation: {
    mb: 4
  },
  specialty: {
    color: `text`,
    mb: 4
  }
}

export default () => (
  <>
    <Heading variant='h1'>
      Install Swift on <Text sx={styles.author}>RISC-V</Text>.
    </Heading>
    {/* <Heading variant='h1' sx={styles.occupation}>
      UX/UI Designer
    </Heading> */}
    <Heading variant='h3' sx={styles.specialty}>
      <b>Swift</b> is an exciting Open Source coding language developed by
      Apple.
    </Heading>
    <Heading variant='h3' sx={styles.specialty}>
      <b>Swift</b> on <b>RISC-V</b> is all about getting <b>Swift</b> onto{' '}
      <b>RISC-V</b> devices. <br />
      This can be anything from small developer boards and IOT devices, to high
      performance cloud servers and PC's.
    </Heading>
    <Box variant='buttons.group'>
      {/* <Button as={Link} to='/contact'>
        Contact Me
      </Button> */}
      {/* <Button variant='white' as={Link} to='/about'>
        About Me
      </Button> */}
    </Box>
  </>
)
